body, html, #root {
  margin: 0;
  font-family: Futura;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "Caudex";
  src: local("Caudex"),
    url("./assets/fonts/Caudex-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Caudex";
  src: local("Caudex"),
    url("./assets/fonts/Caudex-Bold.ttf") format("truetype");
  font-style: bold;
}

@font-face {
  font-family: "Caudex";
  src: local("Caudex"),
    url("./assets/fonts/Caudex-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "EB Garamond";
  src: local("EB Garamond"),
    url("./assets/fonts/EBGaramond-Italic-VariableFont_wght.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "EB Garamond";
  src: local("EB Garamond"),
    url("./assets/fonts/EBGaramond-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url("./assets/fonts/Jost-VariableFont_wght.ttf") format("truetype");
}